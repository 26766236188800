import { Button, Form, Input } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import lock from "../../assets/img/dashboard/lock.svg";
import logonew from '../../assets/img/dashboard/logonew.svg';
import user from "../../assets/img/dashboard/userIcon.svg";
import { actionLogin } from '../../store/services/authService';

const LoginNew = React.memo(() => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loginStatus = useSelector(state => state.auth);
    const token = localStorage.getItem('pinalBackendJwtToken');

    useEffect(() => {
        if (token) {
            navigate('/');
        }
    }, [token, navigate]);




    const onFinish = (values) => {
        dispatch(actionLogin({ values, navigate }));
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    return (
        <div className=' flex justify-center py-10 items-center h-screen max-h-screen ' id="login-container-new">
            <div className='login-card shadow-[0_0px_15px_0px_rgba(0,0,0,0.10)] 2xl:p-10 xl:p-8 lg:p-8  p-4 pb-10 md:my-3 sm:mx-5 bg-[#fff] rounded-2xl '>

                <div className='pb-5 2xl:pb-10 xl:pb-8 lg:pb-6'>
                    <img src={logonew} alt="Logo" className='mx-auto w-[200px]' />
                </div>
                <p className='font-Glegoo 2xl:text-[36px] xl:text-[30px] lg:text-[30px] text-[25px] font-[700] ' >Login</p>
                <p className='custom-login-text custom-sixt font-gilroyMedium w-[80%] mx-auto mt-2  capitalize text-[#2B2A28]' >Please Enter Your Username/Email and Password To have an access of the admin portal</p>

                <Form className='mt-5 2xl:mt-10 xl:mt-8 lg:mt-6'
                    name="basic"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item className='text-left'
                        // label="Username"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: "Please enter your email or username"
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
                                    const isUsername = /^[a-zA-Z0-9_]+$/.test(value);

                                    if (value && (!isEmail && !isUsername)) {
                                        return Promise.reject('Please enter a valid username or email!');
                                    }


                                    // You can add additional conditions if needed

                                    return Promise.resolve();
                                },
                            }),
                        ]}
                    >
                        <Input placeholder='Username/Email' prefix={<img src={user} alt="user" className='pr-2 border-r' />} className='login-input py-[0px] border border-[#919da9]' />
                    </Form.Item>

                    <Form.Item
                        className='mt-5 text-left'
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                    >
                        <Input.Password placeholder='Password' prefix={<img src={lock} alt="user" className='pr-2 border-r' />} className='login-input py-[3px] border border-[#919da9]' />
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit" loading={loginStatus?.loginLoader} className='custom-sixt mt-4'>
                            Login
                        </Button>
                    </Form.Item>

                    <Link to={"/forgot-password"}>
                        <div className='text-[#00205E] text-[16px] font-gilroyBold underline'>Forgot Password?</div>
                    </Link>
                </Form>


            </div>
            {/* </Col>
            </Row> */}
        </div>
    )
})

export default LoginNew
