import { CloseOutlined } from '@ant-design/icons';
import { Button, Calendar, Col, DatePicker, Divider, Image, Input, Modal, Pagination, Row, Segmented, Switch, Table, Typography, Dropdown } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import absent from '../../assets/img/all/absent.svg';
import allemp from '../../assets/img/all/allemp.svg';
import half from '../../assets/img/all/halfday.svg';
import human from '../../assets/img/all/human.svg';
import url from '../../assets/img/all/member.svg';
import night from '../../assets/img/all/night.svg';
import present from '../../assets/img/all/present.svg';
import logo from '../../assets/img/all/report-logo.svg';
import search from "../../assets/img/all/search.svg";
import download from "../../assets/img/all/download.svg";
import SideCardHeader from '../common/SideCardHeader';

const Hr = () => {
    const data = [];
    const [open, setOpen] = useState(false);
    const [date, setDate] = useState();
    const [currentDate, setCurrentDate] = useState(moment())

    for (let i = 0; i <= 100; i++) {
        data.push({
            key: i + 1,
            num: (pagination.current - 1) * pagination.pageSize + i + 1,
            employee_name: 'Ishita Raj',
            email: 'tanu.ganesh@mail.com',
            username: 'TanuGanesh01',
            morning: "A",
            afternoon: "P",
            evening: "A",
            night: "P",
        })
    }
    const [data1, setData1] = useState(data);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    });
    const [paginatedData, setPaginatedData] = useState(data1.slice(0, pagination.pageSize));

    const handleTableChange = (page, pageSize) => {
        const start = (page - 1) * pageSize;
        const end = start + pageSize;
        const paginatedData = data.slice(start, end);

        setPagination({
            current: page,
            pageSize,
            total: data.length,
        });
        setPaginatedData(paginatedData);
    };


    const handleSwitchChange = (checked, key, period) => {
        setData1((prevData) =>
            prevData.map((item) =>
                item.key === key ? { ...item, [period]: checked ? 'P' : 'A' } : item
            )
        );
    };

    const onPanelChange = (value) => {
        setCurrentDate(moment(value.$d).format("YYYY-MM-DD"));

        console.log({ currentDate });
    };

    const columns = [
        {
            title: '#',
            dataIndex: 'num',
            key: 'num',
            render: (text) => <p>{text}</p>,
            // width: 80,
        },
        {
            title: 'Employee Name',
            dataIndex: 'employee_name',
            key: 'employee_name',
            // width: 200,
            render: (text) => <div className='flex items-center gap-x-3'><div className='border rounded-full overflow-hidden bg-transparent border-primary'><img src={human} alt="Human" /></div><p>{text}</p></div>
        },
        {
            title: 'Email Address',
            dataIndex: 'email',
            key: 'email',
            ellipsis: true,
            // width: 250
        },
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
            ellipsis: true,
            // width: 200
        },
        {
            title: 'Morning',
            dataIndex: 'morning',
            key: 'morning',
            ellipsis: true,
            render: (text, record) => (
                <div id="custom-switch">
                    <Switch
                        style={{ width: 10, height: 22 }}
                        checkedChildren="P"
                        unCheckedChildren="A"
                        defaultChecked={text === 'P'}  // Use text === 'P' to determine if it's checked
                        onChange={(checked) => handleSwitchChange(checked, record.key, 'morning')}
                    />
                </div>
            ),
        },
        {
            title: 'Afternoon',
            dataIndex: 'afternoon',
            key: 'afternoon',
            ellipsis: true,
            render: (text, record) => (
                <div id="custom-switch">
                    <Switch
                        style={{ width: 10, height: 22 }}
                        checkedChildren="P"
                        unCheckedChildren="A"
                        defaultChecked={text === "P"}
                        onChange={(checked) => handleSwitchChange(checked, record.key, 'afternoon')}
                    />
                </div>
            ),
        },
        {
            title: 'Evening',
            dataIndex: 'evening',
            key: 'evening',
            ellipsis: true,
            render: (text, record) => (
                <div id="custom-switch">
                    <Switch
                        style={{ width: 10, height: 22 }}
                        checkedChildren="P"
                        unCheckedChildren="A"
                        defaultChecked={text === "P"}
                        onChange={(checked) => handleSwitchChange(checked, record.key, 'evening')}
                    />
                </div>
            ),
        },
        {
            title: 'Night',
            dataIndex: 'night',
            key: 'night',
            ellipsis: true,
            render: (text, record) => (
                <div id="custom-switch">
                    <Switch
                        style={{ width: 10, height: 22 }}
                        checkedChildren="P"
                        unCheckedChildren="A"
                        defaultChecked={text === "P"}
                        onChange={(checked) => handleSwitchChange(checked, record.key, 'night')}
                    />
                </div>
            ),
        },
    ];

    const tableColumns = [
        {
            title: 'Employee Name',
            dataIndex: 'emp_name',
            align: 'left',
            width: 350,
            ellipsis: true,
            render: (text) => <p>{text}</p>,
        },
        {
            title: 'Present',
            dataIndex: 'present',
            align: 'middle',
        },
        {
            title: 'Absent',
            dataIndex: 'absent',
            align: 'middle',
        },
        {
            title: 'HalfDay',
            dataIndex: 'halfday',
            align: 'middle',
        },
        {
            title: 'Total Days',
            dataIndex: 'total_days',
            align: 'middle',
        },
        {
            title: 'Total Nights',
            dataIndex: 'total_nights',
            align: 'middle'
        }
    ];

    const tableData = [];
    for (let i = 1; i < 10; i++) {
        tableData.push({
            emp_name: 'John Doe',
            present: 10,
            absent: 10,
            halfday: 10,
            total_days: 10,
            total_nights: 10,
        })
    }
    const onChange = (val) => {
        console.log({ val, date });
        setDate(val);
    };

    const disabledDate = (current) => {
        // Disable dates before the start of the current month
        return current && current < moment().startOf('month');
    };

    const items = [
        {
            key: '1',
            label: (
                <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
                    Tarun’s Attendance Report
                </a>
            ),
        },
        {
            key: '2',
            label: (
                <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
                    Tarun’s Salary Report
                </a>
            ),
        }
    ];

    return (
        <div className='flex justify-center h-full max-h-screen gatepass-container'>
            <div className='bg-[#F4F8FF] w-[70%] relative custom-scrollbar flex flex-col overflow-hidden'>


                <Row
                    justify={'center'}
                    align={'middle'}
                    className='border-b border-b-[#919da94d] items-center justify-between 2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[40px]'
                >
                    <Col span={12}>
                        <p className='font-GlegooBold text-primary 2xl:text-[36px] xl:text-[26px] lg:text-[18px] ml-5'>
                            HRMS
                        </p>
                    </Col>
                    <Col span={6} align="end">
                        <div className='mr-5 w-[80%] ml-auto'>
                            <DatePicker className='search-datepicker' onChange={onChange} />
                        </div>
                    </Col>
                    <Col span={6} align="end">
                        <div className='mr-5 ml-auto'>
                            <Input className='custom-search' placeholder="Search Employee" suffix={<img src={search} alt="search" />} />
                        </div>
                    </Col>

                </Row>
                <div className='flex-1 overflow-auto  px-4 py-4 scrollbar-container'>
                    <Row className='border-b border-b-[rgba(145, 157, 169, 0.3)]'>
                        <Col xxl={15} xl={24} lg={24} sm={24} className='px-6 pb-4  '>
                            <Segmented className='custom-segment bg-main'
                                options={[
                                    {
                                        label: 'All Employee (34)',
                                        value: 'All Employee (34)',
                                        icon: <Image preview={false} src={allemp} />,
                                    },
                                    {
                                        label: 'Present (0)',
                                        value: 'Present (0)',
                                        icon: <Image preview={false} src={present} />,
                                    },
                                    {
                                        label: 'Absent (34)',
                                        value: 'Absent (34)',
                                        icon: <Image preview={false} src={absent} />,
                                    },
                                    {
                                        label: 'Half Day (0)',
                                        value: 'Half Day (0)',
                                        icon: <Image preview={false} src={half} />,
                                    },
                                    {
                                        label: 'Night (0)',
                                        value: 'Night (0)',
                                        icon: <Image preview={false} src={night} />,
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                    <div className='px-3 overflow-auto'>
                        <Table columns={columns}
                            pagination={false}
                            scroll={{
                                x: 'max-content'
                            }}
                            onChange={(e, page) => handleTableChange(pagination.current, pagination.pageSize)}
                            dataSource={paginatedData} />
                    </div>
                </div>
                <div className='flex items-center justify-between 2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[50px] bg-transparent border-t border-t-custom-gray border-opacity-[30%] px-5' align={'middle'}>
                    {/* <Row className='py-1   border-t-custom-gray border-opacity-[30%] px-5' align={'middle'}> */}
                    <div className='flex space-x-1'>
                        <Button onClick={() => setOpen(true)} className='filled-btn 2xl:text-[16px] xl:text-[14px] text-[12px]'>Attendance Report</Button>
                        <Button onClick={() => setOpen(true)} className='filled-btn 2xl:text-[16px] xl:text-[14px] text-[12px]'>Salary Report</Button>
                    </div>

                    <div className='flex justify-end' id='custom-pagination'>
                        <Pagination
                            className='flex items-center'
                            {...pagination}
                            showSizeChanger
                            showQuickJumper
                            pageSizeOptions={['10', '20', '30']}
                            total={data.length}
                            onChange={(page, pageSize) => handleTableChange(page, pageSize)}
                        />
                    </div>
                </div>
            </div>

            {/* SIDEBAR START HERE */}
            <div className='bg-[#FFFFFF] border-l border-l-[#919da94d] w-[30%] flex flex-col '>
                <SideCardHeader />
                <div className='overflow-auto custom-scrollbar  px-1 flex-1 py-1'>

                    <div className='px-4'>
                        {/* Hello PX-4 */}
                        <div className=' mx-auto flex flex-col items-center justify-center w-full  py-4 border-opacity-[30%] border-custom-gray'>
                            <div className='flex w-[59.5%] ml-auto'>
                                <img src={url} alt="URL" />
                                <div className='ml-auto'>
                                    <Dropdown
                                        menu={{
                                            items,
                                        }}
                                    >
                                        <Button size='middle' className='bg-[#DFDEFF] h-auto 2xl:p-3 xl:p-2.5 p-2 ml-auto border-0 flex justify-center items-center rounded-[10px] group outline-none shadow-none border-none ring-0 focus-visible:outline-transparent focus-visible:ring-0' onClick={(e) => e.preventDefault()}>
                                            {/* <EllipsisOutlined /> */}
                                            <img src={download} alt="dots" />
                                        </Button>
                                    </Dropdown>
                                </div>
                            </div>
                            <p className='text-primary 2xl:text-[20px] xl:text-[16px] text-[12px] font-gilroyBold   lg:text-[14px] font-bold'>Tanu Ganesh</p>
                            <p className='text-[#919DA9] 2xl:text-[10px] xl:text-[10px] font-gilroyMedium lg:text-[10px] text-[10px]'>TanuGanesh01  |  tanu.ganesh@mail.com</p>
                            <Divider />


                            <Calendar className='border hr-calendar mx-0   border-[rgba(145, 157, 169, 0.3)]   rounded-[15px] ' id="custom-calendar"
                                disabledDate={disabledDate}
                                fullscreen={false}
                                headerRender={({ value, type, onChange, onTypeChange }) => {
                                    return (
                                        <div className='flex flex-col justify-center py-2 '>
                                            <p className='px-5 mb-0 text-lg' level={4}>{value.format('MMM YYYY')}</p>
                                            <Divider className='my-2' />
                                            <div className='px-5 py-1 flex items-center justify-between'>
                                                <div className='text-lg   flex flex-col flex-1  justify-start items-center '>
                                                    <Typography.Text ellipsis={true} className='text-[20px] text-primary'>09</Typography.Text>
                                                    <Typography.Text ellipsis={true} className='text-[10px] text-custom-gray'>Present</Typography.Text>
                                                </div>
                                                <div className='text-lg flex flex-1  gap-x-2 '>
                                                    <Divider type="vertical" className='h-[45px] border border-[rgba(145, 157, 169, 0.5)] mx-3' />
                                                    <div>
                                                        <Typography.Text ellipsis={true} className='text-[20px] text-primary'>₹ 300</Typography.Text>
                                                        <Typography.Text ellipsis={true} className='text-[10px] text-custom-gray'>Pay Per Day</Typography.Text>
                                                    </div>
                                                </div>
                                                <div className='text-lg flex flex-1  gap-x-2'>
                                                    <Divider type="vertical" className='h-[45px] border border-[rgba(145, 157, 169, 0.5)] mx-3' />

                                                    <div>
                                                        <Typography.Text ellipsis={true} className='text-[20px] text-primary'>₹ 1000</Typography.Text>
                                                        <Typography.Text ellipsis={true} className='text-[10px] text-custom-gray'>Advance Paid</Typography.Text>
                                                    </div>
                                                </div>
                                                <div className='text-lg flex flex-1 items-center gap-x-2'>
                                                    <Divider type="vertical" className='h-[45px] border border-[rgba(145, 157, 169, 0.5)] mx-3' />
                                                    <div>
                                                        <Typography.Text ellipsis={true} className='text-[20px] text-primary'>₹ 0.00</Typography.Text>
                                                        <Typography.Text ellipsis={true} className='text-[10px] text-custom-gray'>Earned Salary</Typography.Text>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    );
                                }}
                                onChange={(d, a) => onPanelChange(d, a)}
                            />

                        </div>
                    </div>
                </div>
                <div className=' bg-white relative flex gap-x-2 z-50 justify-evenly  px-3 2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[50px]  border-t border-t-custom-gray border-opacity-[30%] 2xl:py-[17px] xl:py-3 py-3'>

                    <Button className='filled-btn 2xl:text-[16px] xl:text-[14px] text-[12px] px-5 w-1/2'>Pay Salary</Button>
                    <Button className='outline-btn 2xl:text-[16px] xl:text-[14px] text-[12px] w-1/2'>Advance Pay</Button>



                </div>
            </div>

            <Modal className='report-modal my-auto scrollbar-container rounded-[20px]'
                closeIcon={<>
                    <div className='absolute right-0 top-0 p-0'>  <CloseOutlined /></div>
                </>}
                centered
                footer={false}
                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                width={'50%'}
            >
                <div className='p-3'>


                    <div className=' flex items-center justify-between'>
                        <div className='w-[30%]'><img src={logo} alt="Logo" /></div>
                        <div className='ml-auto w-[35%]'>
                            <p className='text-[10px] font-gilroyBold text-right text-[rgba(43, 42, 40, 1)]'><span className='font-gilroyRegular'>Address : </span>235, OPP-STAR BAZAR, SOMESHWARA COMPLEX-II,
                                SATELLITE ROAD, SATELLITE, Ahmedabad-380015 (Gujarat)</p>
                            <br />
                            <p className='text-[10px] font-gilroyBold text-[rgba(43, 42, 40, 1)] text-right'><span className='font-gilroyRegular'>Email : </span>inquiry@pinalsand.com</p>
                            <p className='text-[10px] font-gilroyBold text-[rgba(43, 42, 40, 1)] text-right'><span className='font-gilroyRegular'>P : </span>+91 98250 84500 , +91 98250 79433</p>

                        </div>
                    </div>
                    <Divider className='text-[rgba(145, 157, 169, 1)] mb-4' />
                    <div>
                        <p className='text-primary mb-2 font-GlegooBold 2xl:text-[24px] xl:text-[20px] text-[16px] '>Attendance Report November,2023</p>
                        <Table className=''
                            sticky
                            scroll={{
                                x: '',
                                // y: window.innerWidth < 1500 ? 350 : ''
                            }}

                            columns={tableColumns}
                            dataSource={tableData}
                            pagination={false}
                            bordered />
                    </div>
                </div>
            </Modal>
        </div >
    )
}

export default Hr
