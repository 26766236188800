import {
    EyeOutlined
} from "@ant-design/icons";
import { Button, Card, Col, Descriptions, Empty, Flex, Image, Input, Pagination, Popconfirm, Row, Spin, Table } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import call from "../../assets/img/all/call.svg";
import callicon from "../../assets/img/all/callicon.svg";
import message from "../../assets/img/all/message.svg";
import search from "../../assets/img/all/search.svg";
import { actionDeleteCompany, actionGetCompany, actionGetCompanyDetail } from '../../store/services/companyService';
import SideCardHeader from '../common/SideCardHeader';


const Company = () => {
    const companyData = useSelector(state => state.company);
    const { companies, companiesCount, companyDetailLoader, deleteCompanyLoader, companyDetail, getCompanyLoader } = companyData;
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [selectedRowKey, setSelectedRowKey] = useState(0);
    const [staticId, setStaticId] = useState();

    const [keyword, setKeyword] = useState("");

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: companiesCount
    });


    useEffect(() => {
        if (keyword) {
            dispatch(actionGetCompany({ offset: 0, limit: 10, keyword: keyword ? keyword : "" }));
        } else {
            dispatch(actionGetCompany({ offset: pagination.current - 1, limit: pagination.pageSize, keyword: keyword ? keyword : "", }))
        }
    }, [dispatch, keyword, pagination])



    useMemo(() => {
        const newData = companies && companies?.length && companies?.map((company, i) => ({
            key: i,
            num: (pagination.current - 1) * pagination.pageSize + i + 1,
            id: company.id,
            person_name: company.person_name,
            business_name: company.business_name,
            city_state: company.city + ", " + company.state,
            phone: company.phone,
            logo: <Image height={50} preview={{ mask: <EyeOutlined /> }} className='rounded-md' width={50} src={company?.logo} alt="Logo" />,
            address: company.address,
            pincode: company.pincode,
            person_email: company.person_email ? company.person_email : "-",
        }));
        setData(newData);
        companies && companies.length && setStaticId(companies[0]?.id);
    }, [companyData]);


    useEffect(() => {
        staticId && dispatch(actionGetCompanyDetail(staticId));
        setSelectedRowKey(0);
    }, [staticId]);

    const handleTableChange = (page, pageSize) => {
        setPagination({
            current: page,
            pageSize,
            total: companiesCount,
        });
    };

    const confirm = (id) => {
        dispatch(actionDeleteCompany(id))
            .then(response => {
                try {
                    if (companyData.deleteSuccess) {
                        const deletedRowIndex = companies.findIndex(item => item.id === id);
                        const newStaticId = deletedRowIndex > 0 ? companyData.companies[deletedRowIndex - 1].id : null;
                        setSelectedRowKey(deletedRowIndex);
                        dispatch(actionGetCompanyDetail(newStaticId));
                    } else {
                        console.log("Error Occurs");
                    }
                } catch (err) {
                    console.log({ err });
                }

            })
            .catch(error => {
                console.error("Error:", error);
            });
    };

    const columns = [
        {
            title: '#',
            dataIndex: 'num',
            key: 'num',
            render: (text, record, index) => <p>{index + 1 + (pagination.current - 1) * pagination.pageSize}</p>,
            width: 50,
        },
        {
            title: 'Business Name',
            dataIndex: 'business_name',
            key: 'business_name',
            ellipsis: true,
            // width: 250,
            render: (item) => <p>{item.length > 50 ? item.slice(0, 30) + "..." : item}</p>,


        },
        {
            title: 'Person Name',
            dataIndex: 'person_name',
            key: 'person_name',
            ellipsis: true,
            // width: 150,

        },
        {
            title: 'Person Email',
            dataIndex: 'person_email',
            key: 'person_email',
            ellipsis: true,
            // width: 150,

        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            ellipsis: true,
            // width: 150,

        },
        {
            title: 'City, State',
            dataIndex: 'city_state',
            key: 'city_state',
            ellipsis: true,
            // width: 150,

        },
        {
            title: 'Image',
            dataIndex: 'logo',
            key: 'logo',
            ellipsis: true,
            width: 150,

        },

    ];

    const desitems = [];

if (companyDetail?.gst_in) {
    desitems.push({
        key: '1',
        label: 'GSTIN',
        children: companyDetail.gst_in,
    });
}

    if (companyDetail?.iec_code) {
        desitems.push({
            key: '1',
            label: 'IEC No',
            children: companyDetail.iec_code,
        });
    }

desitems.push(
    {
        key: '2',
        label: 'Business Address',
        children: companyDetail?.delivery_address ? companyDetail.delivery_address : "-",
    },
    {
        key: '3',
        label: 'Business Pincode',
        children: companyDetail?.delivery_pincode ? companyDetail.delivery_pincode : "-",
    },
    {
        key: '4',
        label: 'City, State',
        children: `${companyDetail?.city}, ${companyDetail?.state}`,
    },
    {
        key: '5',
        label: 'Address',
        children: companyDetail?.address ? companyDetail.address + " " + companyDetail.pincode : "-",
    },
    {
        key: '6',
        label: 'Email',
        children: companyDetail && companyDetail.person_email ? companyDetail.person_email : "-",
    }
);

    // const desitems = [
    //     {
    //         key: '1',
    //         label: 'GSTIN',
    //         children: companyDetail?.gst_in || "-",
    //     },
    //     {
    //         key: '7',
    //         label: 'IEC No',
    //         children: companyDetail?.iec_code || "-",
    //     },
    //     {
    //         key: '2',
    //         label: 'Business Address',
    //         children: companyDetail?.delivery_address ? companyDetail?.delivery_address : "-",
    //     },
    //     {
    //         key: '2',
    //         label: 'Business Pincode',
    //         children: companyDetail?.delivery_pincode ? companyDetail?.delivery_pincode : "-",
    //     },
    //     {
    //         key: '3',
    //         label: 'City, State',
    //         children: `${companyDetail?.city}, ${companyDetail?.state}`,
    //     },

    //     {
    //         key: '5',
    //         label: 'Address',
    //         children: companyDetail?.address ? companyDetail?.address + " " + companyDetail?.pincode : "-",
    //     },
    //     {
    //         key: '6',
    //         label: 'Email',
    //         children: companyDetail && companyDetail?.person_email ? companyDetail && companyDetail?.person_email : "-",
    //     },
    // ];

    return (
        <div className='flex justify-center h-full max-h-screen gatepass-container'>
            {/* Content Section */}
            <div className='bg-[#F4F8FF] w-[70%] relative custom-scrollbar flex flex-col overflow-hidden'>
                <Row
                    justify={'center'}
                    align={'middle'}
                    className='border-b border-b-[#919da94d] items-center justify-between 2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[40px]'
                >
                    <Col span={6}>
                        <p className='font-GlegooBold text-primary 2xl:text-[36px] xl:text-[26px] lg:text-[18px] ml-5'>
                            Company
                        </p>
                    </Col>
                    <Col span={14} xxl={12} lg={18} sm={18} xl={18} align="end">
                        <div className='mr-5 w-1/2 ml-auto'>
                            <Input className='custom-search' placeholder="Search Company" onChange={(e) => setKeyword(e.target.value)} suffix={<img src={search} alt="search" />} />
                        </div>
                    </Col>

                </Row>
                <div className='flex-1 overflow-hidden px-6 py-4 scrollbar-container'>
                    <Table className="table-scrollbar"
                        loading={getCompanyLoader}
                        rowClassName={(record) => (record.key === selectedRowKey ? 'selected-row' : '')}
                        onRow={(record) => ({
                            onClick: () => {
                                if (record.key !== selectedRowKey) {
                                    dispatch(actionGetCompanyDetail(record.id))
                                    setSelectedRowKey(record.key)
                                }
                            }
                        })}
                        scroll={{
                            // x: "max-content",
                            y: window.innerWidth < 1500 ? "62vh" : '70vh',
                        }}
                        pagination={false}
                        onChange={(e, page) => handleTableChange(pagination.current, pagination.pageSize)}
                        columns={columns} dataSource={data} />
                </div>
                <div className='flex items-center justify-between 2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[50px] border-t border-t-custom-gray border-opacity-[30%] px-5' align={'middle'}>

                    <Link to="/add-company">
                        <Button className='filled-btn 2xl:text-[16px]  xl:text-[14px] text-[12px]'>Add New Company</Button>
                    </Link>
                    <div className=' flex justify-end' id='custom-pagination'>
                        <Pagination
                            hideOnSinglePage
                            className='flex items-center'
                            {...pagination}
                            showSizeChanger
                            showQuickJumper
                            pageSizeOptions={['10', '20', '30']}
                            total={companiesCount}
                            onChange={(page, pageSize) => handleTableChange(page, pageSize)}
                        />
                    </div>
                </div>
            </div>
            {/* Content Section */}


            {/* Sidebar  Section Start Here */}
            <div className='bg-white  border-l border-l-[#919da94d] w-[30%] flex flex-col'>
                <SideCardHeader />

                {
                    companies && companies?.length ? <><div className='overflow-auto custom-scrollbar  px-1 flex-1 py-1'>

                        <Spin spinning={companyDetailLoader || getCompanyLoader}>

                            <div className='px-4 '>

                                <div className=' mx-auto flex flex-col items-center justify-center w-full border-b py-4 border-opacity-[30%] border-custom-gray'>
                                    <Image className="rounded-md" preview={false} height={100} width={100} src={companyDetail?.logo} alt="URL" />
                                    <p className='text-primary 2xl:text-[20px] xl:text-[16px] text-[12px] font-gilroyBold   lg:text-[14px] font-bold'>{companyDetail?.business_name}</p>
                                    <p className='text-[#919DA9] 2xl:text-[10px] xl:text-[10px] font-gilroyMedium lg:text-[10px] text-[10px]'>
                                        {companyDetail?.pincode
                                            ? `${companyDetail?.pincode} | ${companyDetail?.city}, ${companyData.companyDetail?.state}`
                                            : "-"}
                                    </p>
                                </div>

                                <Descriptions column={2} layout="vertical" id='gatepass-description' className='mt-4' items={desitems} />

                                <p className='text-[12px] text-custom-gray mb-2'>Contact Person</p>
                                <Card className='companycard'>
                                    <Flex justify='space-between'>
                                        <div>
                                            <p className='custom-fourt text-primary font-gilroyBold'>{companyDetail?.person_name}</p>
                                            <p className='text-custom-gray 2xl:text-[12px] xl:text-12px text-[10px] flex'><img src={callicon} alt="call" className='mr-1' />{companyData.companyDetail?.country_phone_code} {companyData.companyDetail?.phone}</p>
                                        </div>
                                        <div className='flex items-center gap-x-3'>
                                            <a href="tel:+9106187681">
                                                <span type='link' className='p-3 border border-custom-gray hover:border-custom-gray flex items-center rounded-[15px] cursor-pointer'><img src={message} alt="message" /></span></a>
                                            <a href="tel:+9106187681">     <span type='link' className='p-3 border border-custom-gray hover:border-custom-gray flex items-center rounded-[15px] cursor-pointer'><img src={call} alt="call" /></span></a>
                                        </div>
                                    </Flex>
                                </Card>

                                <p className='text-[12px] text-custom-gray mt-4 mb-1'>Business Name</p>
                                <p className='text-primary custom-fourt'>{companyDetail?.business_name}</p>


                            </div>
                        </Spin>

                    </div>
                        <div className=' bg-white relative gap-x-2 flex z-50 justify-evenly  px-3 2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[50px]  border-t border-t-custom-gray border-opacity-[30%] 2xl:py-[17px] xl:py-3 py-3'>

                            <Link to={`/edit-company/${companyDetail?.id}`} className={'w-1/2 text-center flex items-center justify-center filled-btn 2xl:text-[16px] xl:text-[14px] text-[12px] px-5'}>Edit Details</Link>

                            <Popconfirm
                                okButtonProps={{
                                    loading: deleteCompanyLoader,
                                }}
                                placement="top"
                                title="Are you sure you want to delete Company?"
                                onConfirm={() => confirm(companyData && companyDetail && companyDetail?.id)}
                                okText="Yes"
                                cancelText="No">
                                <Button className='warning-btn 2xl:text-[16px] xl:text-[14px] text-[12px] px-5 w-1/2'>Delete Company</Button>
                            </Popconfirm>



                        </div>
                    </> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="pt-10 flex items-center justify-center flex-col" />
                }

            </div >
            {/* Sidebar  Section Start Here */}
        </div >
    );
};

export default Company;
