import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import MainLayout from '../common/MainLayout';

const PrivateRoute = ({ component }) => {
    const token = localStorage.getItem('pinalBackendJwtToken');
    const navigate = useNavigate();

    useEffect(() => {
        if (!token) {
            navigate('/login');
        }
    }, [token, navigate]);

    return (
        <MainLayout>
            {component}
        </MainLayout>
    )
}

export default PrivateRoute;
