import { Button, Form, Input } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import lock from "../../assets/img/dashboard/lock.svg";
import logonew from '../../assets/img/dashboard/logonew.svg';
import { actionResetPassword } from '../../store/services/authService';

const ResetPassword = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loginStatus = useSelector(state => state.auth);
    const token = useLocation();
    const tokenSubmit = token.search.split("=")[1];

    const onFinish = (values) => {
        const req = {
            password: values.password,
            token: tokenSubmit
        }
        dispatch(actionResetPassword({ req, navigate }));
        console.log(values, "reset password")
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    return (
        <div className=' flex justify-center py-10 items-center h-screen max-h-screen ' id="login-container-new">
            <div className='login-card shadow-[0_0px_15px_0px_rgba(0,0,0,0.10)] 2xl:p-10 xl:p-8 lg:p-8  p-4 pb-10 md:my-3 sm:mx-5 bg-[#fff] rounded-2xl '>

                <div className='pb-5 2xl:pb-10 xl:pb-8 lg:pb-6'>
                    <img src={logonew} placeholder="Logo" className='mx-auto w-[200px]' />
                </div>
                <p className='font-Glegoo 2xl:text-[36px] xl:text-[30px] lg:text-[30px] text-[25px] font-[700] ' >Reset Password</p>
                <Form className='mt-5 2xl:mt-10 xl:mt-8 lg:mt-6'
                    name="basic"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        className='mt-5 text-left'
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your new password!',
                            },
                            {
                                min: 8,
                                message:
                                    "passwords must be longer than or equal to 8 characters !",
                            },
                        ]}
                    >
                        <Input.Password placeholder='New Password' prefix={<img src={lock} alt="user" className='pr-2 border-r' />} className='login-input py-[3px] border border-[#919da9]' />
                    </Form.Item>
                    <Form.Item
                        className='mt-5 text-left'
                        name="confirm_new_password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your confirm new password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue("password") === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error(
                                            "The two passwords that you entered do not match!"
                                        )
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input.Password placeholder='Confirm New Password' prefix={<img src={lock} alt="user" className='pr-2 border-r' />} className='login-input py-[3px] border border-[#919da9]' />
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit"
                            loading={loginStatus?.resetPasswordLoader}
                            className='custom-sixt mt-4'>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default ResetPassword