import { ConfigProvider, Image, theme } from 'antd';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import logo from './assets/img/dashboard/logo.svg';
import './assets/scss/index.scss';
import AddGatepass from './components/Gatepass/AddGatepass';
import EditGatepass from './components/Gatepass/EditGatepass';
import Gatepass from './components/Gatepass/Gatepass';
import Hr from './components/HR/Hr';
import AddLot from './components/LOT/AddLot';
import Lot from './components/LOT/Lot';
import ChangePassword from './components/auth/ChangePassword';
import ForgotPassword from './components/auth/ForgotPassword';
import LoginNew from './components/auth/LoginNew';
import PrivateRoute from './components/auth/PrivateRoute';
import ResetPassword from './components/auth/ResetPassword';
import NotFound from './components/common/NotFound';
import setAuthToken from './components/config/setAuthToken';
import AddCompany from './components/company/AddCompany';
import Company from './components/company/Company';
import EditCompany from './components/company/EditCompany';
import Dashboard from './components/dashboard/Dashboard';
import AddDispatch from './components/dispatches/AddDispatch';
import Dispatches from './components/dispatches/Dispatches';
import EditDispatch from './components/dispatches/EditDispatch';
import Machine from './components/machine/Machine';
import Rbac2 from './components/rbac/Rbac2';
// import Rbac from './components/rbac/Rbac';
import Roles from './components/rbac/Roles';
import AddMember from './components/system-management/AddMember';
import EditMember from './components/system-management/EditMember';
import SystemManagement from './components/system-management/SystemManagement';
import AddTransportingAgency from './components/transport-agency/AddTransportingAgency';
import EditTransportingAgency from './components/transport-agency/EditTransportingAgency';
import TransportingAgencies from './components/transport-agency/TransportingAgencies';
import { authMe } from './store/services/authService';
import Rbac from './components/rbac/Rbac';
import RoleBaseAcess from './components/rbac/RoleBaseAcess';
import PrintGatepass from './components/dispatches/PrintGatepass';


function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth);
  const token = localStorage.getItem('pinalBackendJwtToken');

  useMemo(() => {
    if (token) {
      setAuthToken(token);
      dispatch(authMe());
    }
  }, [token, dispatch])


  const themeStyle = {
    token: {
      fontFamily: 'Gilroy-Medium',
    },
    // algorithm: theme.darkAlgorithm,
    components: {
      Menu: {
        itemSelectedColor: 'rgba(255, 90, 38, 1)',
        itemColor: 'rgba(0, 32, 94, 0.5)',
        itemSelectedBg: 'transparent',
        fontFamily: 'Glegoo-Bold',
        itemHoverBg: 'transparent',
      },
      Spin: {
        colorPrimary: '#00205E'
      },
    },
  };


  return (
    <ConfigProvider theme={themeStyle}>
      <BrowserRouter>
        {user.userLoader ? (
          <div className="flex items-center h-screen justify-center">
            <Image preview={false} src={logo} />
          </div>
        ) :
          <Routes>
            <Route path='/' element={<PrivateRoute component={<Dashboard />} />} />
            <Route path="/batch" element={<PrivateRoute component={<Lot />} />} />
            <Route path="/add-batch" element={<PrivateRoute component={<AddLot />} />} />
            <Route path="/gatepass" element={<PrivateRoute component={<Gatepass />} />} />
            <Route path="/add-gatepass" element={<PrivateRoute component={<AddGatepass />} />} />
            <Route path="/edit-gatepass/:id" element={<PrivateRoute component={<EditGatepass />} />} />
            <Route path="/hr" element={<PrivateRoute component={<Hr />} />} />
            <Route path="/system-management" element={<PrivateRoute component={<SystemManagement />} />} />
            <Route path="/dispatch-batch" element={<PrivateRoute component={<Dispatches />} />} />
            <Route path="/print-gatepass/:id" element={<PrintGatepass />} />
            <Route path="/add-dispatch-batch" element={<PrivateRoute component={<AddDispatch />} />} />
            <Route path="/edit-dispatch-batch/:Id" element={<PrivateRoute component={<EditDispatch />} />} />
            <Route path="/transport-agencies" element={<PrivateRoute component={<TransportingAgencies />} />} />
            <Route path="/add-transport-agency" element={<PrivateRoute component={<AddTransportingAgency />} />} />
            <Route path="/edit-transport-agency/:id" element={<PrivateRoute component={<EditTransportingAgency />} />} />
            <Route path="/company" element={<PrivateRoute component={<Company />} />} />
            <Route path="/add-company" element={<PrivateRoute component={<AddCompany />} />} />
            <Route path="/edit-company/:Id" element={<PrivateRoute component={<EditCompany />} />} />
            <Route path="/add-member" element={<PrivateRoute component={<AddMember />} />} />
            <Route path="/edit-member/:id" element={<PrivateRoute component={<EditMember />} />} />
            <Route path="/roles" element={<PrivateRoute component={<Roles />} />} />
            {/* <Route path="/view-role/:Id" element={<PrivateRoute component={<Rbac2 />} />} /> */}
            {/* <Route path="/view-role/:Id" element={<PrivateRoute component={<RoleBaseAcess />} />} /> */}
            {/* <Route path="/view-role/:Id" element={<PrivateRoute component={<Rbac />} />} /> */}
            <Route path="/change-password" element={<PrivateRoute component={<ChangePassword />} />} />
            <Route path="/machine" element={<PrivateRoute component={<Machine />} />} />
            <Route path='/login' element={<LoginNew />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/reset-password' element={<ResetPassword />} />
            <Route path='/*' element={<NotFound />} />
          </Routes>
        }
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
