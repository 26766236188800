import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Input, Row, Select, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import usersvg from "../../assets/img/dashboard/user.svg";
import {
  actionGetCities,
  actionGetCountries,
  actionGetStates,
  actionCreateCompany,
  actionGetCompanyDetail,
  actionUpdateCompany,
  actionGetDeliveryStates,
  actionGetDeliveryCities,
  actionGetDeliveryCountries,
} from "../../store/services/companyService";
import SideCardHeader from "../common/SideCardHeader";
import { BASE_URL_UPLOAD } from "../config/web.config";
import { useNavigate, useParams } from "react-router-dom";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const EditCompany = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Id } = useParams();
  const [form] = Form.useForm();
  const location = useSelector((state) => state.company);
  const {
    companyDetail,
    statesLoader,
    getCountryLoader,
    deliveryCitiesLoader,
    citiesLoader,
    countries,
    states,
    cities,
  } = location;
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [useSameAddress, setUseSameAddress] = useState(true);
  const [sId, setSId] = useState("");
  const [deliverySId, setDeliverySId] = useState("");

  useEffect(() => {
    dispatch(actionGetCountries());
    dispatch(actionGetDeliveryCountries());
  }, [dispatch]);

  useEffect(() => {
    if (Id) {
      dispatch(actionGetCompanyDetail(Id));
    }
  }, [dispatch]);

  useEffect(() => {
    if (companyDetail) {
      form.setFieldsValue({
        business_name: companyDetail.business_name,
        gst_in: companyDetail.gst_in,
        delivery_pincode: companyDetail.delivery_pincode,
        delivery_country: companyDetail.delivery_country_code,
        delivery_address: companyDetail.delivery_address,
        delivery_state: companyDetail.delivery_state,
        person_name: companyDetail.person_name,
        delivery_city: companyDetail.delivery_city,
        phone: companyDetail.phone,
        country_phone_code: companyDetail.country_phone_code,
        address: companyDetail.address,
        country: companyDetail.country_code,
        state: companyDetail.state,
        city: companyDetail.city,
        pincode: companyDetail.pincode,
        person_email: companyDetail.person_email,
        iec_code: companyDetail.iec_code,
      });
      setImageUrl(companyDetail.logo);
      setUseSameAddress(companyDetail.is_same_address);
    }
  }, [companyDetail]);

  const country =
    (countries &&
      countries.length &&
      countries?.map(({ name, isoCode, ...data }) => ({
        ...data,
        label: name,
        value: isoCode,
      }))) ||
    [];

  const state =
    (states &&
      states.length &&
      states?.map(({ name, isoCode, ...data }) => ({
        ...data,
        label: name,
        value: isoCode,
      }))) ||
    [];

  const city =
    (cities &&
      cities.length &&
      cities?.map(({ name, isoCode, ...data }) => ({
        ...data,
        label: name,
        value: name,
      }))) ||
    [];

  const deliveryCountry =
    (location?.deliveryCountries &&
      location?.deliveryCountries.length &&
      location?.deliveryCountries?.map(({ name, isoCode, ...data }) => ({
        ...data,
        label: name,
        value: isoCode,
      }))) ||
    [];

  const deliveryState =
    (location?.getDeliveryStates &&
      location?.getDeliveryStates.length &&
      location?.getDeliveryStates?.map(({ name, isoCode, ...data }) => ({
        ...data,
        label: name,
        value: isoCode,
      }))) ||
    [];

  const deliveryCity =
    (location?.getDeliveryCities &&
      location?.getDeliveryCities.length &&
      location?.getDeliveryCities?.map(({ name, isoCode, ...data }) => ({
        ...data,
        label: name,
        value: name,
      }))) ||
    [];

  const handleGetState = (val, data) => {
    dispatch(actionGetStates(val));
    form.setFieldsValue({
      state: null,
      city: null,
    });
  };

  const handleGetCity = (val, data) => {
    console.log({ val, data });
    setSId(val);
    dispatch(
      actionGetCities({ countryCode: data?.countryCode, stateCode: data.value })
    );
    form.setFieldsValue({
      city: null,
    });
  };

  const handleGetDeliveryState = (val) => {
    dispatch(actionGetDeliveryStates(val));
    form.setFieldsValue({
      delivery_state: null,
      delivery_city: null,
    });
  };
  const handleGetDeliveryCity = (val, data) => {
    setDeliverySId(val);
    dispatch(
      actionGetDeliveryCities({
        countryCode: data?.countryCode,
        stateCode: data.value,
      })
    );
    form.setFieldsValue({
      delivery_city: null,
    });
  };
  console.log(companyDetail.delivery_country);

  useEffect(() => {
    if (companyDetail && companyDetail.country) {
      dispatch(actionGetStates(companyDetail.country_code));
    }
    if (companyDetail && companyDetail.delivery_country) {
      dispatch(actionGetDeliveryStates(companyDetail.delivery_country_code));
    }
    if (companyDetail && companyDetail.state && !citiesLoader) {
      dispatch(
        actionGetCities({
          countryCode: companyDetail.country_code,
          stateCode: companyDetail.state_code,
        })
      );
    }
    if (companyDetail && companyDetail.deliveryState && !deliveryCitiesLoader) {
      dispatch(
        actionGetDeliveryCities({
          countryCode: companyDetail.delivery_country_code,
          stateCode: companyDetail.delivery_state_code,
        })
      );
    }
  }, [companyDetail]);

  const onChange = (val) => {
    console.log({ val });
  };

  const uploadButton = (
    <button type="button">
      {loading ? (
        <LoadingOutlined className="text-[#919DA9]" />
      ) : (
        <PlusOutlined className="text-[#919DA9]" />
      )}
      <div className="text-[16px] text-[#919DA9] font-gilroyMedium">Upload</div>
    </button>
  );

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(info.file.response.file.path);
      });
    }
  };

  const onFinish = (values) => {
    const stateCode = state?.find((d) => d.label === values.state);
    const bStateCode = deliveryState?.find(
      (d) => d.label === values.delivery_state
    );
    const isIndia = values.country == "IN";
    const req = {
      ...values,
      state: stateCode ? stateCode.value : sId,
      delivery_state: bStateCode ? bStateCode.value : deliverySId,
      gst_in: isIndia ? values.gst_in : "",
      /*  delivery_address: useSameAddress ? values.address : values.delivery_address ? values.delivery_address : "",
             delivery_country: useSameAddress ? values.country : values.delivery_country ? values.delivery_country : "", */
      /*    delivery_state: useSameAddress ? values.state : values.delivery_state ? values.delivery_state : "", */
      /*   delivery_city: useSameAddress ? values.city : values.delivery_city ? values.delivery_city : "",
              delivery_pincode: useSameAddress ? values.pincode : values.delivery_pincode ? values.delivery_pincode : "", */

      logo: imageUrl
        ? imageUrl?.split("/").reverse()[0]
        : values.logo.file.response.file.filename,
      is_same_address: useSameAddress ? 1 : 0,
    };
    dispatch(actionUpdateCompany({ Id, req, navigate }));
    console.log({ req });
  };

  return (
    // Optional Foooter so add new Structure
    <Form
      layout="vertical"
      initialValues={{
        country: !companyDetail.country_code && "IN",
        delivery_country: !companyDetail.delivery_country_code && "IN",
        country_phone_code: !companyDetail.country_phone_code && "+91",
      }}
      form={form}
      onFinish={onFinish}
      className="h-full relative max-h-screen gatepass-container overflow-hidden"
    >
      <div className="flex justify-center h-full ">
        <div className="bg-[#F4F8FF] w-[70%] relative custom-scrollbar flex flex-col overflow-hidden">
          <div className="border-b border-b-[#919da94d] flex items-center 2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[40px]">
            <p className="font-GlegooBold text-primary 2xl:text-[36px] xl:text-[26px] lg:text-[18px] ml-5">
              Edit Company
            </p>
          </div>
          <div className=" scrollbar-container flex-1 overflow-auto px-6 py-4 xl:pb-20 2xl:pb-20  pb-20 ">
            <Row gutter={[16, 8]}>
              <Col span={24}>
                <div className="flex items-center gap-x-2 mb-4">
                  <img src={usersvg} alt="User Svg" />{" "}
                  <p className="2xl:text-base xl:text-sm text-xs  font-GlegooBold text-primary">
                    Company Details
                  </p>
                </div>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Business Name"
                  name="business_name"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Business Name!",
                    },
                  ]}
                >
                  <Input
                    className="custom-input-group"
                    placeholder="Enter Business Name"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Business Address"
                  name="address"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Business Address!",
                    },
                  ]}
                >
                  <Input placeholder="Enter Business Address" />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Country"
                  name="country"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Country!",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    defaultValue="IN"
                    showSearch
                    onSelect={handleGetState}
                    placeholder="Select Country"
                    optionFilterProp="children"
                    className=""
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={country}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="State"
                  name="state"
                  rules={[
                    {
                      required: true,
                      message: "Please Select State!",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    onSelect={handleGetCity}
                    placeholder="Select State"
                    optionFilterProp="children"
                    className=""
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={state}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="City/Town"
                  name="city"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter City/Town!",
                    },
                  ]}
                >
                  <Input placeholder="Enter city" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Pincode"
                  name="pincode"
                  normalize={(value) =>
                    value.replace(/[^a-zA-Z0-9]/g, "").trim()
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Pincode!",
                    },
                    /*  ({ getFieldValue }) => ({
                                             validator(_, value) {
                                                 if (value && (value.length !== 6)) {
                                                     return Promise.reject('Please enter a valid 6-digit PIN code.!');
                                                 }
 
                                                 // You can add additional conditions if needed
 
                                                 return Promise.resolve();
                                             },
                                         }) */
                  ]}
                >
                  <Input placeholder="Enter Pincode" />
                </Form.Item>
              </Col>
              {
                form.getFieldValue("country") == "IN" ? (
                  <Col span={12}>
                    <Form.Item
                      label="GSTIN Number"
                      name="gst_in"
                      rules={[
                        {
                          required: false,
                          message: "Please Enter GSTIN Number!",
                        },
                        {
                          pattern:
                            /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Za-z]{1}[Z]{1}[0-9A-Za-z]{1}$/,
                          message: "Please enter a valid GSTIN No!",
                        },
                      ]}
                    >
                      <Input placeholder="Enter GSTIN Number" />
                    </Form.Item>
                  </Col>
                ) : (
                  <Col span={12}></Col>
                )
                // <Col span={12}>
                //     <Form.Item
                //         label="IEC Number"
                //         name="iec_code"
                //         rules={[
                //             {
                //                 required: true,
                //                 message: 'Please Enter IEC Number!',
                //             },
                //             ({ getFieldValue }) => ({
                //                 validator(_, value) {
                //                     if (value && value.length >= 20) {
                //                         return Promise.reject("The entered value should be shorter than 20 characters!");
                //                     }

                //                     // You can add additional conditions if needed

                //                     return Promise.resolve();
                //                 },
                //             }),
                //         ]}
                //     >
                //         <Input placeholder='Enter IEC Number' />
                //     </Form.Item>
                // </Col>
              }
              <Col span={24}>
                <Form.Item
                  className="mb-0"
                  rules={[
                    {
                      required: false,
                      message: "",
                    },
                  ]}
                >
                  <Checkbox
                    checked={useSameAddress}
                    onChange={() => setUseSameAddress(!useSameAddress)}
                    className="hover:border-custom-gray custom-checkbox"
                  >
                    <p className="text-custom-gray">
                      Use same Address for the{" "}
                      <span className="text-primary">Delivery Address</span>
                    </p>
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="profile_pic" label="Company Logo">
                  <ImgCrop rotationSlider aspect={1 / 1} fillColor="white">
                    <Upload
                      name="file"
                      onRemove={() => setImageUrl("")}
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      action={BASE_URL_UPLOAD}
                      accept="image/png, image/jpeg ,image/png, image/jpg"
                      //   beforeUpload={beforeUpload}
                      headers={{
                        Authorization:
                          "Bearer " +
                          localStorage.getItem("pinalBackendJwtToken"),
                      }}
                      onChange={handleChange}
                    >
                      {imageUrl ? (
                        <img
                          src={imageUrl}
                          alt="avatar"
                          className="p-1"
                          style={{
                            width: "100%",
                          }}
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  </ImgCrop>
                </Form.Item>
              </Col>

              {!useSameAddress && (
                <>
                  <Col span={12}>
                    <Form.Item
                      label="Delivery Address"
                      name="delivery_address"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Delivery Address!",
                        },
                      ]}
                    >
                      <Input placeholder="Enter Business Address" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label="Pincode"
                      name="delivery_pincode"
                      normalize={(value) =>
                        value.replace(/[^a-zA-Z0-9]/g, "").trim()
                      }
                      rules={[
                        {
                          required: false,
                          message: "Please Enter Pincode!",
                        },
                        /*  ({ getFieldValue }) => ({
                                                     validator(_, value) {
                                                         if (value && (value.length !== 6)) {
                                                             return Promise.reject('Please enter a valid 6-digit PIN code.!');
                                                         }
 
                                                         // You can add additional conditions if needed
 
                                                         return Promise.resolve();
                                                     },
                                                 }) */
                      ]}
                    >
                      <Input placeholder="Enter Pincode" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      label="Delivery Country"
                      name="delivery_country"
                      rules={[
                        {
                          required: true,
                          message: "Please Select Country!",
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        showSearch
                        onSelect={handleGetDeliveryState}
                        placeholder="Select Country"
                        optionFilterProp="children"
                        className=""
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={deliveryCountry}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Delivery State"
                      name="delivery_state"
                      rules={[
                        {
                          required: true,
                          message: "Please Select State!",
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        showSearch
                        onSelect={handleGetDeliveryCity}
                        placeholder="Select State"
                        optionFilterProp="children"
                        className=""
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={deliveryState}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Delivery City/Town"
                      name="delivery_city"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter City/Town!",
                        },
                      ]}
                    >
                      {/* <Select allowClear
                                                showSearch
                                                placeholder="Select City/Town"
                                                optionFilterProp="children" className=''
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                                options={deliveryCity}
                                            /> */}
                      <Input placeholder="Enter Delivery city" />
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
          </div>
        </div>

        {/* SIDEBAR START HERE */}
        <div className="bg-main border-l  border-l-[#919da94d] w-[30%] flex flex-col ">
          <SideCardHeader />
          <div className="overflow-auto custom-scrollbar  px-1 flex-1 py-1 2xl:pb-0 xl:pb-14">
            <div className="px-4 pt-5" id="custom-increment-input">
              <div className="flex items-center gap-x-2 mb-4">
                <img src={usersvg} alt="User Svg" />{" "}
                <p className="2xl:text-base xl:text-sm text-xs  font-GlegooBold text-primary">
                  Person Details
                </p>
              </div>
            </div>
            <Row className="px-4" gutter={[16, 0]}>
              <Col span={24}>
                <Form.Item
                  label="Person Name"
                  name="person_name"
                  normalize={(value) => value.replace(/[^a-zA-Z\s]/g, "")}
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Person Name!",
                    },
                  ]}
                >
                  <Input placeholder="Enter Person Name" onChange={onChange} />
                </Form.Item>
              </Col>

              {/* <Col span={24}>
                <Form.Item
                  label="Contact Number"
                  name="phone"
                  normalize={(value) => value.replace(/[^0-9]/g, "").trim()}
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Contact Number!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (value && value.length !== 10) {
                          return Promise.reject(
                            "Please enter a valid 10-digit mobile number! "
                          );
                        }

                        // You can add additional conditions if needed

                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    controls={false}
                    placeholder="Enter Contact Number"
                    className="rounded-0 w-full border-0 country-input"
                  />
                </Form.Item>
              </Col> */}
              <Col span={24} style={{ display: "flex",gap:'10px'  }}>
                <Form.Item
                  style={{ width: "30%" }}
                  label="Country Code"
                  name="country_phone_code"
                //   normalize={(value) => value.replace(/[^0-9]/g, "").trim()}
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Country Code!",
                    },
                  ]}
                >
                  <Input
                    controls={false}
                    placeholder="Country Code"
                    className="rounded-0 w-full border-0 country-input"
                  />
                </Form.Item>
                <Form.Item
                  style={{ width: "70%" }}
                  label="Contact Number"
                  name="phone"
                  normalize={(value) => value.replace(/[^0-9]/g, "").trim()}
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Contact Number!",
                    },
                    // ({ getFieldValue }) => ({
                    //   validator(_, value) {
                    //     if (value && value.length !== 10) {
                    //       return Promise.reject(
                    //         "Please enter a valid 10-digit mobile number! "
                    //       );
                    //     }
                    //     return Promise.resolve();
                    //   },
                    // }),
                  ]}
                >
                  <Input
                    controls={false}
                    placeholder="Enter Contact Number"
                    className="rounded-0 w-full border-0 country-input"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Email"
                  name="person_email"
                  rules={[
                    {
                      type: "email",
                      required: false,
                      message: "Please Enter Email !",
                    },
                  ]}
                >
                  <Input placeholder="Enter Email " />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      {/* Footer Section */}
      <div className="footer z-0 bg-white sticky flex items-center px-4 gap-x-2 bottom-0 w-full  border-t border-t-[rgba(145, 157, 169, 0.3)]  bg-transparent 2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[50px]  2xl:py-[17px]  xl:py-3 py-3">
        <Form.Item style={{ marginBottom: 0 }}>
          <Button
            htmlType="submit"
            loading={location.createCompanyLoader}
            className="filled-btn 2xl:text-[16px] xl:text-[14px] text-[12px]"
          >
            Save Customer
          </Button>
        </Form.Item>
        <Button
          onClick={() => navigate(-1)}
          className="warning-btn 2xl:text-[16px] xl:text-[14px] text-[12px]"
        >
          Discard Entry
        </Button>
      </div>
      {/* Footer Section */}
    </Form>
  );
};

export default EditCompany;
