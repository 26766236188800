import { Image, Layout, Menu, theme } from 'antd';
import { SafetyCertificateOutlined, RobotOutlined } from '@ant-design/icons';
import React, { useMemo, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import dashImage from '../../assets/img/dashboard/dashboardicon.svg';
import dashImageActive from '../../assets/img/dashboard/dashboardiconActive.svg';
import logo from '../../assets/img/dashboard/dashboardlogo.svg';
import dispatch from '../../assets/img/dashboard/dispatch.svg';
import dispatchActive from '../../assets/img/dashboard/dispatchActive.svg';
import gatepass from '../../assets/img/dashboard/gatepass.svg';
import gatepassActive from '../../assets/img/dashboard/gatepassActive.svg';
import hrimage from '../../assets/img/dashboard/hrimage.svg';
import hrimageActive from '../../assets/img/dashboard/hrimageActive.svg';
import mlogo from '../../assets/img/dashboard/logo.svg';
import lotimage from '../../assets/img/dashboard/lotimage.svg';
import lotimageActive from '../../assets/img/dashboard/lotimageActive.svg';
import smanagement from '../../assets/img/dashboard/smanagement.svg';
import smanagementActive from '../../assets/img/dashboard/smanagementActive.svg';
import truck from '../../assets/img/dashboard/truck.svg';
import truckActive from '../../assets/img/dashboard/truckActive.svg';
import user from '../../assets/img/dashboard/user.svg';
import userActive from '../../assets/img/dashboard/userActive.svg';
import machineActive from '../../assets/img/dashboard/machineActive.svg';
import machine from '../../assets/img/dashboard/machine.svg';
import rbac from '../../assets/img/dashboard/rolebase.svg';
import rbacActive from '../../assets/img/dashboard/rolebaseActive.svg';

const { Sider, Content } = Layout;
const MainLayout = ({ children }) => {
    const [collapsed, setCollapsed] = useState(true);
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const [key, setKey] = useState('1');
    const history = useLocation();

    useMemo(() => {
        const currentPath = history.pathname;
        const pathToKeyMapping = {
            '': '1',
            'batch': '2',
            'add-batch': '2',
            // 'gatepass': '3',
            // 'add-gatepass': '3',
            // 'edit-gatepass': '3',
            'company': '4',
            'add-company': '4',
            'edit-company': '4',
            'transport-agencies': '5',
            'add-transport-agency': '5',
            'edit-transport-agency': '5',
            'dispatch-batch': '6',
            'add-dispatch-batch': '6',
            'edit-dispatch-batch': '6',
            'hr': '7',
            'system-management': '8',
            'edit-member': '8',
            'add-member': '8',
            'roles': '9',
            'machine': '10',
        };
        const currentPathWithoutParams = currentPath.split('/');
        setKey(pathToKeyMapping[currentPathWithoutParams[1]] || '1');
    }, [history.pathname]);


    const items = [
        { key: '1', icon: <img className='h-[20px] w-[20px]' src={`${key === '1' ? dashImageActive : dashImage}`} alt="dashImage" />, label: <NavLink className="" to={'/'}>Dashboard</NavLink>, path: '/' },
        { key: '2', icon: <img className='h-[20px] w-[20px]' src={`${key === '2' ? lotimageActive : lotimage}`} alt="lotimage" />, label: <NavLink className="" to={'/batch'}>Batch</NavLink>, path: '/batch' },
        // { key: '3', icon: <img className='h-[20px] w-[20px]' src={`${key === '3' ? gatepassActive : gatepass}`} alt="gatepass" />, label: <NavLink className="" to={'/gatepass'}>Gatepass</NavLink>, path: '/gatepass' },
        { key: '4', icon: <img className='h-[20px] w-[20px]' src={`${key === '4' ? userActive : user}`} alt="Company" />, label: <NavLink className="" to={'/company'}>Company</NavLink>, path: '/company' },
        { key: '5', icon: <img className='h-[20px] w-[20px]' src={`${key === '5' ? truckActive : truck}`} alt="truck" />, label: <NavLink className="" to={'/transport-agencies'}>Transport Agencies</NavLink>, path: '/transport-agencies' },
        { key: '6', icon: <img className='h-[20px] w-[20px]' src={`${key === '6' ? dispatchActive : dispatch}`} alt="dispatch" />, label: <NavLink className="" to={'/dispatch-batch'}>Dispatch Batch</NavLink>, path: '/dispatch-batch' },
        //{ key: '7', icon: <img className='h-[20px] w-[20px]' src={`${key === '7' ? hrimageActive : hrimage}`} alt="hrimage" />, label: <NavLink className="" to={'/hr'}>HR</NavLink>, path: '/hr' },
        { key: '8', icon: <img className='h-[20px] w-[20px]' src={`${key === '8' ? smanagementActive : smanagement}`} alt="System Management" />, label: <NavLink className="" to={'/system-management'}>System Management</NavLink>, path: '/system-management' },
        { key: '9', icon: <img className='h-[20px] w-[20px]' src={`${key === '9' ? rbacActive : rbac}`} alt="rbac" />, label: <NavLink className="" to={'/roles'}>RBAC</NavLink>, path: '/roles' },
        { key: '10', icon: <img className='h-[20px] w-[20px]' src={`${key === '10' ? machineActive : machine}`} alt="machine" />, label: <NavLink className="" to={'/machine'}>Machine</NavLink>, path: '/machine' },
    ];

    return (
        <Layout style={{ height: '100vh' }}>
            <Sider className={`transition-all h-full ${history.pathname === '/login' ? "hidden" : ""}`}
                style={{ transition: 'all 0.5s ease', position: 'absolute', zIndex: 4, maxWidth: 200, backgroundColor: 'white' }}
                onMouseEnter={() => {
                    setCollapsed(false);
                }}
                onMouseLeave={() => {
                    setCollapsed(true);
                }}
                trigger={null} collapsible collapsed={collapsed}

            >
                <Link to="/">  <div className={`demo-logo-vertical my-3 mb-5 ${collapsed ? 'text-center' : 'ml-6'} pt-4 pb-3`}>
                    {collapsed ? <Image preview={false} src={logo} height={50} alt="Dashboard Logo" /> : <Image preview={false} height={50} src={mlogo} alt="Dashboard Logo" />}
                </div></Link>
                <Menu
                    theme="light"
                    mode="inline"
                    className='bg-transparent'
                    defaultSelectedKeys={[key]}
                    items={items}
                />

            </Sider>
            <Layout className='ml-[80px]'>
                <Content
                    style={{
                        overflow: 'initial',
                        minHeight: 280,
                        background: colorBgContainer,
                    }}
                >
                    {children}
                </Content>
            </Layout>
        </Layout >
    );
};
export default MainLayout;